.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px; /* Смещение формы от верха страницы */
  height: 100vh;
  background: linear-gradient(135deg, #007bff, #00d2ff);
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.loginForm h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.loginForm h3 {
  font-size: 1.5rem;
  margin-bottom: 2rem; /* Увеличенный отступ под заголовком */
  color: #555;
  font-style: italic;
  text-align: center;
}

.loginForm form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin-top: 30px; /* Увеличенное расстояние между заголовком и формой */
}

.loginForm input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.loginForm button {
  width: 100%;
  padding: 0.5rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.loginForm button:hover {
  background-color: #0056b3;
}
