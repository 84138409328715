.user-list-container {
    padding: 20px;
  }
  
  .user-list-header {
  /*  display: flex;*/
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .user-create-form {
    margin-bottom: 20px;
    
  }
  
  .user-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .user-table th, .user-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .user-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  input {
    padding: 6px;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
  }
  
  input:focus {
    border-color: #305A72;
    outline: none;
  }

  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 10px;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  