/* Основной контейнер для списка документов и iframe */
.document-list-wrapper {
    display: flex;
    flex-direction: row;
    /* Элементы будут располагаться горизонтально */
    padding: 20px;
    background-color: #f9f9f9;
    /* Светлый фон для всего компонента */
}

/* Контейнер для заголовка и кнопки */
.document-list-header {
    display: flex;
    justify-content: space-between;
    /* Размещаем заголовок и кнопку на одной линии */
    align-items: center;
    /* Выравниваем элементы по вертикали */
    margin-bottom: 20px;
    /* Отступ снизу */
}

/* Заголовок таблицы */
h2 {
    margin: 0;
    /* Убираем стандартные отступы */
    font-size: 24px;
    color: #333;
}

/* Контейнер для таблицы со списком документов */
.document-list-container {
    flex-grow: 1;
    /* Таблица занимает все доступное пространство */
    margin-right: 20px;
    /* Отступ справа от таблицы */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    /* Округленные углы */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Легкая тень */
}

/* Контейнер для iframe */
.iframe-container {
    flex-shrink: 0;
    background-color: #fff;
    padding: 100px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Стили для кнопки "Создать документ" */
.document-create-button {
    display: flex;
    justify-content: flex-end;
    height: 40px;    /* Размещаем кнопку справа */
}

button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

/* Стили для кнопки "Редактировать" */
button.edit-button {
    background-color: rgb(209, 69, 69);
    /* Красный фон */
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
}

button.edit-button:hover {
    background-color: darkred;
    /* Темно-красный при наведении */
}

/* Стиль для метки фильтра */
label {
    display: block;
    /* Метка на отдельной строке */
    margin-bottom: 15px;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
}

/* Стиль для выпадающего списка фильтра */
select {
    margin-left: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* Округлённые углы для выпадающего списка */
}

/* Стиль таблицы */
table {
    width: 100%;
    /* Таблица растягивается на всю ширину контейнера */
    border-collapse: collapse;
    /* Убираем двойные границы между ячейками */
    margin-bottom: 20px;
}

/* Стиль для ячеек заголовков таблицы */
th,
td {
    padding: 6px;
    /* Отступы внутри ячеек */
    text-align: left;
    border-bottom: 1px solid #ddd;
    /* Нижняя граница строк */
}

/* Заголовки таблицы с фоновым цветом */
th {
    background-color: #f2f2f2;
    color: #333;
    text-align: center;
    /* Центрируем текст заголовков */
}

/* Стиль при наведении на строки таблицы */
tbody tr:hover {
    background-color: #f1f1f1;
    /* Легкий оттенок при наведении */
}

/* Группировка кнопок */
.button-group {
    display: flex;
    gap: 30px;
    /* Расстояние между кнопками */
    justify-content: flex-end;
}

/* Модальное окно (фон) */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Прозрачный черный фон */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Стиль модального окна */
.modal-content {
    position: relative;
    /* Устанавливаем относительное позиционирование для контейнера */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    /* Убедитесь, что оно выше фона модального окна */
}

/* Стиль кнопки закрытия модального окна */
.close-modal-button {
    background-color: #d9534f;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    /* Фиксируем позицию */
    top: 10px;
    right: 10px;
    /* Прижимаем кнопку к правому краю */
}

/* Стиль кнопки закрытия при наведении */
.close-modal-button:hover {
    background-color: #c9302c;
}

/* Пагинация: контейнер с кнопками страниц */
.pagination {
    display: flex;
    justify-content: center;
    /* Центрируем кнопки пагинации */
    margin-top: 20px;
}

/* Стиль кнопок пагинации */
.page-button {
    margin: 0 5px;
    /* Отступы между кнопками */
    padding: 8px 12px;
    border: 1px solid #ccc;
    background-color: rgba(153, 151, 151, 0.685);
    cursor: pointer;
    border-radius: 4px;
    /* Округлённые углы кнопок */
}

/* Активная кнопка пагинации */
.page-button.active {
    background-color: #007bff;
    /* Синий фон для активной кнопки */
    color: white;
    border-color: #007bff;
}

/* Стиль кнопок пагинации при наведении */
.page-button:hover {
    background-color: #f0f0f0;
    /* Светло-серый фон при наведении */
}